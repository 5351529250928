<template>
  <div class="page-settings">
    <settings-navigation />
    <div class="agGrid-page container">
      <base-header />
      <base-ag-grid
        :columnDefs="columnDefs"
        :datasource="datasource"
        :cellValueChanged="cellValueChanged"
      />
    </div>
  </div>
</template>

<script>
// Components
import BaseAgGrid from '@/agGridV2/components/base.component'
import SettingsNavigation from '../components/SettingsNavigation'
// API Init
import MtApi from '@/agGridV2/helpers/mt-api.helper'
import SimpleHelper from '@/agGridV2/helpers/simple.helper'
// Custom Cell Renderers
import CheckboxRenderer from '@/agGridV2/renderers/checkbox.renderer'
import SimpleSelectRenderer from '@/agGridV2/renderers/simple-select.renderer'
import ObjectSelectRenderer from '@/agGridV2/renderers/object-select.renderer.vue'
import BaseHeader from '@/agGridV2/components/header/header.component.vue'

export default {
  components: {
    BaseHeader,
    BaseAgGrid,
    SettingsNavigation,
    // eslint-disable-next-line vue/no-unused-components
    CheckboxRenderer,
    // eslint-disable-next-line vue/no-unused-components
    SimpleSelectRenderer,
    // eslint-disable-next-line vue/no-unused-components
    ObjectSelectRenderer
  },
  data() {
    return {
      datasource: null,
      columnDefs: null,
      cellValueChanged: null
    }
  },
  async mounted() {
    const [activityTags, staffs] = await Promise.all([
      MtApi.getActivityTags(),
      MtApi.getStaffs()
    ])
    const groupedActivityTags = this.transformOptions(activityTags)
    this.columnDefs = [
      {
        field: 'status',
        rowGroup: true,
        hide: true
      },
      {
        field: 'clickup_user_name',
        headerName: 'Name',
        cellRenderer: (params) => {
          if (params.node.group) {
            return ''
          }
          let value = params.value
          value += ` <svg fill="${params.data.clickup_user_api_key ? '#7987FF' : '#d7d7d7'}" data-v-30dcd6e4="" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" viewBox="0 0 30 30"><path data-v-30dcd6e4="" d="M 18.5 3 C 13.806 3 10 6.806 10 11.5 C 10 12.542294 10.19765 13.536204 10.541016 14.458984 L 3 22 L 3 27 L 8 27 L 8 24 L 11 24 L 11 21 L 14 21 L 15.541016 19.458984 C 16.463796 19.80235 17.457706 20 18.5 20 C 23.194 20 27 16.194 27 11.5 C 27 6.806 23.194 3 18.5 3 z M 20.5 7 C 21.881 7 23 8.119 23 9.5 C 23 10.881 21.881 12 20.5 12 C 19.119 12 18 10.881 18 9.5 C 18 8.119 19.119 7 20.5 7 z"></path></svg>`
          return value
        }
      },
      {
        field: 'clickup_user_email',
        headerName: 'Email'
      },
      {
        field: 'system_role',
        headerName: 'System Role',
        cellRenderer: 'SimpleSelectRenderer',
        cellRendererParams: {
          options: [
            {
              label: 'Admin',
              value: 'admin'
            },
            {
              label: 'Supervisor',
              value: 'supervisor'
            },
            {
              label: 'Manager',
              value: 'manager'
            },
            {
              label: 'Doer',
              value: 'doer'
            },
            {
              label: 'Guest',
              value: 'guest'
            }
          ]
        },
        filter: 'agSetColumnFilter',
        filterParams: {
          values: ['admin', 'supervisor', 'manager', 'doer', 'guest'],
          valueFormatter: (params) => {
            if (params.value === 'admin') {
              return 'Admin'
            } else if (params.value === 'supervisor') {
              return 'Supervisor'
            } else if (params.value === 'manager') {
              return 'Manager'
            } else if (params.value === 'doer') {
              return 'Doer'
            } else if (params.value === 'guest') {
              return 'Guest'
            }
            return params.value
          }
        }
      },
      {
        field: 'rate',
        headerName: 'Rate',
        editable: true,
        valueFormatter: (params) => {
          return SimpleHelper.priceFormatter(params.value)
        },
        filter: false
      },
      {
        field: 'experience',
        headerName: 'Experience',
        cellRenderer: 'SimpleSelectRenderer',
        cellRendererParams: {
          options: [
            {
              label: 'Junior',
              value: 0.5
            },
            {
              label: 'Middle',
              value: 0.75
            },
            {
              label: 'Senior',
              value: 1
            }
          ]
        },
        filter: false
      },
      {
        field: 'activity_tag',
        headerName: 'Activity Tag',
        cellRenderer: 'ObjectSelectRenderer',
        cellRendererParams: {
          options: groupedActivityTags,
          field: 'name'
        },
        filter: false,
        width: 250
      },
      {
        field: 'supervisor',
        headerName: 'Supervisor',
        cellRenderer: 'ObjectSelectRenderer',
        cellRendererParams: {
          options: staffs,
          field: 'clickup_user_name'
        },
        filter: false
      }
    ]
    this.datasource = {
      getRows: (params) => {
        params.request['search'] = this.$store.getters.getSearchQuery(this.$route.name)
        MtApi.agGridUsersSettings(params.request).then((response) => {
          this.$store.dispatch('setAgGridRowsCount', { page: this.$route.name, query: response.count })
          this.$emitter.emit('set_agGrid_rows_count', {})
          params.success({ rowData: response.rows, rowCount: response.count })
          const firstGroupNode = params.api.getDisplayedRowAtIndex(0)
          if (firstGroupNode && firstGroupNode.group) {
            firstGroupNode.setExpanded(true)
          }
        })
      }
    }
    this.cellValueChanged = async (params) => {
      const data = {
        event: 'update_staff',
        field: {
          name: params.colDef.field,
          value: params.value
        }
      }
      await MtApi.updateStaff(params.data._id, data)
    }
  },
  methods: {
    transformOptions(options) {
      let result = []
      let handledIds = new Set()
      options.forEach((option) => {
        if (!handledIds.has(option._id)) {
          if (!option.parent_tag) {
            result.push({
              ...option,
              group: true,
              disabled: true
            })
            handledIds.add(option._id)
            options.forEach((child) => {
              if (child.parent_tag === option._id) {
                result.push({ ...child, name: `-- ${child.name}` })
                handledIds.add(child._id)
              }
            })
          }
        }
      })
      return result
    }
  }
}
</script>
